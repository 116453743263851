const environment = 'dev' // 'prod' or 'dev'

const configurations = {
  prod: {
    baseURL: process.env.VUE_APP_API_BASE_URL_PROD,
    clientId: process.env.VUE_APP_API_CLIENT_ID_PROD
  },
  dev: {
    baseURL: process.env.VUE_APP_API_BASE_URL_DEV,
    clientId: process.env.VUE_APP_API_CLIENT_ID_DEV
  }
}

export const env = {
  get: name => configurations[environment][name]
}
